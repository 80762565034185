<template>
    <div v-if="!deleted" class="user-add-single">
        <div v-if="done" class="row justify-content-center mt-4">
            <div class="col-8">
                <ScIcon name="check" class="text-success"/>
                &nbsp;
                <router-link :to="{ name: 'user-view', params: { workshopId: mainMxCurrentWorkshopId, userId: userId }}"
                             v-sc-tooltip="'Added successfully, click to edit user'">{{ email }}</router-link>
                &nbsp;
                <span v-if="addedStatusCode === 'emailed_invite'">emailed with an invite link</span>
                <span v-if="addedStatusCode === 'emailed_existing'">already has a password; emailed workshop details</span>
                <span v-if="addedStatusCode === 'added_existing'">already has a password; no email sent</span>
                <span v-if="addedStatusCode === 'added_no_email'">added; no email sent</span>
                <span v-if="addedStatusCode === 'emailed_pwd_managed'">added; password managed externally; emailed workshop details</span>
                <span v-if="addedStatusCode === 'added_pwd_managed'">added; password managed externally; no email sent</span>
            </div>
        </div>
        <div v-else class="mb-3 mt-3">
            <div class="row justify-content-center mt-4">
                <div class="col-9">
                    <label class="font-weight-bold" :for="'email' + id">Email
                        <span v-if="error" class="text-danger ml-4">{{ error }}</span>
                        <span v-if="existsStatusCode === 'not_in_workspace' && advancedAdd" class="text-success ml-4 font-weight-normal">
                            User already has a password, click 'Add User' to add to this Workshop without sending an email.</span>
                        <span v-if="existsStatusCode === 'not_in_workspace' && !advancedAdd" class="text-success ml-4 font-weight-normal">
                            User already has a password, click 'Add User' to add to this Workshop.</span>
                        <span v-if="existsStatusCode === 'managed_externally' && advancedAdd" class="text-success ml-4 font-weight-normal">
                            User's password is managed externally, click 'Add User' to add to this Workshop without sending an email.</span>
                        <span v-if="existsStatusCode === 'managed_externally' && !advancedAdd" class="text-success ml-4 font-weight-normal">
                            User's password is managed externally, click 'Add User' to add to this Workshop.</span>
                        <span v-if="processing"><ScIcon name="spinnerFW" class="ml-2 text-muted" /></span>
                    </label>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-4">
                    <input :id="'email' + id" placeholder="Email" class="form-control input-add-email" name="email"
                       v-model.trim.lazy="email" :disabled="processing" />
                </div>

                <div class="col-2">
                    <div class="customSelectArrow">
                    <select name="role_code" class="form-control bg-white" :disabled="processing" v-model="roleCode">
                        <option value="r:admin">Admin Role</option>
                        <option value="r:manager">Manager Role</option>
                        <option value="r:reporter">Reporter Role</option>
                        <option value="r:editor">Editor Role</option>
                        <option value="r:viewer">Viewer Role</option>
                    </select>
                    </div>
                </div>
                <div class="col-3 justify-content-between d-flex">
                    <div v-if="userId === null" class="form-check my-auto">
                        <input class="form-check-input checkbox-advanced" type="checkbox" :id="id"
                               v-model="sendInviteLink" :disabled="processing" >
                        <label class="form-check-label align-middle" :for="id"><span v-sc-tooltip="'An email will be sent to the address with instructions on setting password and name'">Send invite link</span></label>
                    </div>
                    <span v-if="userId != null" class="my-auto">
                        <strong class="text-info" v-sc-tooltip="'Adding will make them part of this workshop also.'">Part of another workshop</strong>
                    </span>

                    <div class="my-auto">
                        <button :disabled="processing" type="button" class="btn btn-sm btn-link" aria-label="Close" tabindex="-1"
                                @click.prevent="removePendingUser()"
                        ><ScIcon name="trashAltFW" class="text-muted"/></button>
                    </div>
                </div>
            </div>

            <div v-if="advancedAdd" class="row justify-content-center mt-3">
                <div class="col-2">
                    <label class="fw-bold" :for="'addFirstName' + id ">First Name</label>
                    <input :id="'addFirstName' + id " placeholder="First Name" class="form-control" name="firstName"
                           v-model.trim.lazy="firstName"
                        :disabled="disableAdvancedFields" />
                </div>

                <div class="col-3">
                    <label class="fw-bold" :for="'addLastName' + id">Last Name</label>
                    <input :id="'addLastName' + id " placeholder="Last Name" class="form-control"
                           v-model.trim.lazy="lastName" name="lastName"
                        :disabled="disableAdvancedFields" />
                </div>

                <div class="col-2">
                    <span v-sc-tooltip="'Minimum 6 characters'">
                    <label class="fw-bold" :for="'addPwd' + id ">Password</label>
                    <input :id="'addPwd' + id " placeholder="Password" class="form-control" type="password"
                           v-model.trim.lazy="password" name="password" autocomplete="off"
                           :disabled="disableAdvancedFields" />
                    </span>
                </div>

                <div class="col-2">
                    <span v-sc-tooltip="'Confirm the password'">
                    <label class="fw-bold" :for="'addCPwd' + id ">Confirm</label>
                    <input :id="'addCPwd' + id " placeholder="Confirm" class="form-control" type="password"
                           v-model.trim.lazy="password2" name="password2" autocomplete="off"
                           :disabled="disableAdvancedFields" />
                    </span>
                </div>
            </div>

            <div v-if="groupNames && groupNames.length > 0" class="row justify-content-center mt-3">
                <div class="col-9">
                    <label :for="'groups'+ id" class="fw-bold">Groups:</label>
                    <ul :id="'groups'+id" class="mb-0 ms-3 d-inline-block list-inline">
                        <li v-for="(groupName, i) in groupNames" :key="'gk' + i" class="list-inline-item">{{ groupName }}</li>
                    </ul>
                </div>
            </div>
            <div v-if="userWorkspaceRefShown" class="row justify-content-center mt-3">
                <div class="col-9">
                    <label class="fw-bold" :for="'userWorkspaceRef' + id ">User Workshop Reference</label>
                    <input :id="'userWorkspaceRef' + id " placeholder="e.g. Company Name, Site Number" class="form-control" name="userWorkspaceRef"
                           v-model.trim.lazy="userWorkspaceRef" maxlength="250"
                           :disabled="disableAdvancedFields" />
                </div>
            </div>
            <div class="row justify-content-center mt-3 mb-1">
                <div class="col-9 border-top">
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import $ from 'jquery';
    import _ from 'underscore'; // extendOwn
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import MainAppMixin from '../MainAppMixin';

    let DataManager = {
        fetchExistsStatus: function(vm) {
            if (vm.existsStatusCode === 'not_in_workspace' || vm.existsStatusCode === 'managed_externally') {
                // reset fields if starting from not_in_workspace status
                vm.firstName = '';
                vm.lastName = '';
                vm.password = '';
                vm.password2 = '';
            }
            vm.existsStatusCode = '';
            if (!vm.email) return;

            $.ajax({
                type: "POST", url: '/main/users/ajax_user_exists',
                data: {
                    email: vm.email,
                    workspace_id: vm.mainMxCurrentWorkshopId
                }
            }).done(function(data) {
                vm.existsStatusCode = data.user_status;
                vm.error = data.details;

                if ( data.user_status === 'managed_externally') {
                    vm.firstName = null;
                    vm.lastName = null;
                    vm.password = 'xxxxxxxx';
                    vm.password2 = 'xxxxxxxx';  // show a fake pwd
                }
                if ( data.user_status === 'not_in_workspace' && data.user) {
                    if ( data.user.firstName ) vm.firstName = data.user.firstName;
                    if ( data.user.lastName ) vm.lastName = data.user.lastName;
                    vm.password = 'xxxxxxxx';
                    vm.password2 = 'xxxxxxxx';  // show a fake pwd
                }

            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'checking if user exists');
            });
        },

        saveUser: function(vm) {
            return new Promise(function(resolve) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_add_user',
                    contentType: 'application/json; charset=utf-8',
                    data: JSON.stringify({
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        email: vm.email,
                        advancedAdd: vm.advancedAdd,
                        firstName: vm.firstName,
                        lastName: vm.lastName,
                        role_code: vm.roleCode,
                        groupNames: vm.groupNames,
                        password: vm.password,
                        userNewsletterOptIn: vm.userNewsletterOptIn,
                        userOnboardingOptIn: vm.userOnboardingOptIn,
                        userWorkspaceRef: vm.userWorkspaceRef
                    })
                }).then(function (data) {
                    if (data.status === 'success') {
                        vm.userId = data.user_id;
                        vm.addedStatusCode = data.added_status_code;
                        vm.done = true;
                    } else {
                        vm.error = data.details;
                    }

                }).fail(function (jqXhr) {
                    let msg = jqXhr.status + ' error adding ' + vm.email;
                    if (jqXhr && jqXhr.responseJSON && jqXhr.responseJSON.message === 'user_limit_reached') {
                        msg = 'Workshops have a limit in the number of users. To add more users please contact support.';
                    }
                    ScNotification.growlErrMsg(msg);
                }).always(function () {
                    vm.processing = false;
                    resolve();
                });
            });
        }

    };


    let _simpleIdCounter = 0;

    export default {
        name: "UserAddSingle",
        emits: ['update-user'],
        components: {ScIcon},
        props: {
            user: {type: Object},
        },
        data: function () {
            return {
                id: _simpleIdCounter++,
                userId: null,
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                password2: '',
                roleCode: 'r:viewer',
                sendInviteLink: true,
                deleted: false,
                processing: false,
                done: false,
                error: null,
                existsStatusCode: '',
                addedStatusCode: '',
                groupNames: [],
                userNewsletterOptIn: false,
                userOnboardingOptIn: false,
                userWorkspaceRef: null,
                userWorkspaceRefShown: false
            }
        },
        mixins: [ MainAppMixin ],
        mounted: function () {
            _.extendOwn(this, this.user);  // add data from user prop into data
        },

        methods: {
            removePendingUser: function () {
                if (!this.email) {
                    this.deleted = true;
                } else {
                    ScConfirmModal2.modal('Are you sure?').then(() => {
                        this.deleted = true;
                    }, () => {});
                }
            },
            deleteIfEmpty: function () {  // used in UserAddList.vue
                let u = this;
                if (u.deleted) return;
                let userEmpty = false;
                if (u.advancedAdd && u.email === '' && u.password === '' && u.lastName === "" && u.firstName === "") {
                    userEmpty = true;
                } else if (!u.advancedAdd && u.email === '') {
                    userEmpty = true;
                }
                if (userEmpty) u.deleted = true;
            },
            commitWithPromise: function () {
                return new Promise(resolve => {
                    if (this.processing) {
                        DataManager.saveUser(this)
                            .then(() => {
                                this.$emit('update-user', this.$data);
                                resolve();
                            })
                    }   else {
                        resolve();
                    }
                });
            },
            prepareForCommit: function (newsletterOptIn, onboardingOptIn) {
                let u = this;
                //console.log('prepareForCommit', u);
                u.userNewsletterOptIn = newsletterOptIn;
                u.userOnboardingOptIn = onboardingOptIn;
                u.processing = false;
                //console.log('check');
                if (!u.deleted && !u.done && !u.processing && u.existsStatusCode !== 'in_workspace') {
                    u.error = null;
                    if (u.email && u.email.length > 0) {
                        let error = null;
                        if (u.advancedAdd && this.existsStatusCode !== 'managed_externally' && this.existsStatusCode !== 'not_in_workspace') {
                            if (u.password2 !== u.password) error = "Passwords do not match";
                            if (u.password.length < 6) error = "Please enter a password that is at least 6 characters long";
                            if (u.firstName === "") error = "Please enter first name";
                        } else {
                            u.firstName = '';
                            u.lastName = '';
                            u.password = '';
                            u.password2 = '';
                        }
                        if (!ScCommonUtil.validateEmail(u.email)) error = "Please enter a valid email";
                        u.error = error;
                        if (u.error === null) u.processing = true;
                    }   else {
                        u.deleted = true;
                        //console.log('deleting');
                    }
                }
                this.$emit('update-user', u.$data);  // make sure can be seen by UserAddList.vue
                return u.error;
            },
        },

        computed: {
            advancedAdd: function () {
                return !this.sendInviteLink;
            },
            disableAdvancedFields() {
                let arr = ['not_in_workspace', 'managed_externally','in_workspace'];
                return this.processing || arr.includes(this.existsStatusCode);
            }
        },

        watch: {
            email: function () {
                if (this.processing) return;
                this.error = null;
                if (this.email && !ScCommonUtil.validateEmail(this.email)) {
                    this.error = "Please enter a valid email";
                }
                DataManager.fetchExistsStatus(this);
                this.$emit('update-user', this.$data);
            },
            sendInviteLink: function() {
                if (this.processing) return;
                DataManager.fetchExistsStatus(this);
            },
            userWorkspaceRef: function() {
                if (this.userWorkspaceRef) this.userWorkspaceRefShown = true;
            }
        },

    }

</script>
