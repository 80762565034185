<template>
    <ScNormalPageLayout headingPart1="User" headingPart2="directory" submenu="user">
    <div class="container mb-4">
        <UserAddSingle v-for="(pendingUser, i) in users"
                       :ref="setPendingUserCompsRef"
                       :user="pendingUser"
                       @update-user="userUpdate($event, pendingUser)"
                       :key="'pu' + i" />

        <div v-if="pasteEmailsEnabled" class="row justify-content-center mt-4">
            <div class="col-7">
                <div class="row bg-white rounded">
                    <div class="col-4 p-4">
                        <label for="many-emails">Email addresses<br><i>One per line</i></label>
                    </div>
                    <div class="col-8 p-4">
                        <textarea ref="manyEmails" id="many-emails" name="emails" class="form-control w-100" cols="80" rows="8"
                                  autofocus="autofocus"></textarea>
                        <br/>
                        <input @click.prevent="loadPastedEmails()" type="submit" value="Load" class="btn btn-secondary" >
                        <a href="#" @click.prevent="pasteEmailsEnabled=false" class="btn btn-link">Cancel</a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="csvImportEnabled" class="row justify-content-center mt-4">
            <div class="col-7">
                <div class="row bg-white rounded">
                    <div class="col-4 p-4">
                        <label for="import-input" ><strong>Import file (.csv)</strong></label>
                        <br/>
                        Use this
                        <a href="/main/static/import_sample.csv" target="_blank">Sample CSV</a>
                        to structure your data correctly for input
                    </div>
                    <div class="col-8 p-4">
                        <input id="import-input" ref="importInput" class="form-control" name="import-input" type="file"/>
                        <br/>
                        <input @click.prevent="loadCsv()" type="submit" value="Load" class="btn btn-secondary">
                        <a href="#" @click.prevent="csvImportEnabled=false" class="btn btn-link">Cancel</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-7 mt-5 text-center">
                <a href="#" class="btn btn-outline-secondary" @click.prevent="addPendingUser()">
                    <ScIcon name="plus"/> Enter another</a>
                <span class="ms-4 align-middle">Big team?
                    <a @click.prevent="pasteEmailsEnabled=true" href="#">Paste emails</a> or
                    <a @click.prevent="csvImportEnabled=true" href="#">import a CSV file</a></span>
            </div>
            <div class="col-7 mt-3">

                <div class="form-check mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="accept_terms" name="accept_terms"
                               v-model="acceptTerms">
                        <label class="form-check-label" for="accept_terms">I have read, understood and consent to the
                        <a href="https://showcaseworkshop.com/legal/" target="_blank">Terms of Use</a>
                        and the
                        <a href="https://showcaseworkshop.com/privacy/" target="_blank">Privacy Policy</a>,
                        including the collection and processing of my personal data as described in the policies.</label>
                    </div>
                    <div v-if="commitAttempted && !acceptTerms" class="text-danger m-2 mb-4">
                        <strong>Please accept the Terms of Use and Privacy Policy to continue</strong><br/></div>
                </div>

                <div v-if="!mainMxCurrentWorkshop.is_crm_disabled" class="form-check my-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="onboarding_opt_in"
                               v-model="onboardingOptIn">
                        <label class="form-check-label" for="onboarding_opt_in">These users can
                            get a few helpful emails to get them started with Showcase.</label>
                    </div>

                    <div class="form-check mt-2">
                        <input class="form-check-input" type="checkbox" id="newsletter_opt_in"
                               v-model="newsletterOptIn">
                    <label class="form-check-label" for="newsletter_opt_in">
                        These users can get occasional Showcase newsletters with tips and information about new features.

                        <div class="text-muted mt-2">
                            <ScIcon name="questionCircle" class="me-1"/><strong>Note</strong> that users can change their
                            own email preferences when they accept an invite; on their account page; or by unsubscribing
                            through email at any time.
                        </div>
                    </label>
                    </div>
                </div>

                <div v-else class="form-check text-muted my-3">
                    <ScIcon name="questionCircle" class="me-1"/>Note that this workshop has an independently controlled
                    setting that means users only in this workshop will not receive any newsletters or 'getting started'
                    emails. This cannot be overridden for individual users; please contact
                    <a href="mailto:helpdesk@showcaseworkshop.com">helpdesk@showcaseworkshop.com</a>
                    if you wish to change this.
                </div>
            </div>
            <div class="col-7 text-center mt-2">

                <button type="button"
                       @click.prevent="startAddingUsers()" class="btn btn-primary-orange fw-bold mb-2"
                       :disabled="pasteEmailsEnabled || csvImportEnabled || processingUsers || users.length === 0">
                        <span v-if="processingUsers"><ScIcon name="spinnerFW" class="mx-3"/></span>
                        <span v-else-if="(users.length < 2)">Add User</span>
                        <span v-else>Add Users</span>
                </button>
                <p class="mt-3"><ScIcon name="questionCircle"/> Each new user will be subject to an additional monthly
                           <br/>charge, based on your subscription agreement.</p>
            </div>
        </div>

        <ScHelpWidget />

    </div>
    </ScNormalPageLayout>
</template>


<script>

    import _ from "underscore"; // extendOwn, each, keys, map, reject
    import UserAddSingle from "./UserAddSingle.vue";
    import ScNormalPageLayout from "../global/ScNormalPageLayout.vue";
    import ScNotification from "../../shared/common/ScNotification.vue";
    import ScIcon from "../../shared/common/ScIcon.vue";
    import Papa from "papaparse";
    import ScHelpWidget from "../global/ScHelpWidget.vue";
    import MainAppMixin from "../MainAppMixin";

    export default {
        name: "UserAddList",
        components: { ScHelpWidget, ScNormalPageLayout, UserAddSingle, ScIcon },
        data () {
            return {
                pasteEmailsEnabled: false,
                csvImportEnabled: false,
                processingUsers: false,
                users: [],
                acceptTerms: false,
                newsletterOptIn: false,
                onboardingOptIn: false,
                commitAttempted: false,
                pendingUserComps: []

            }
        },
        mixins: [ MainAppMixin ],
        mounted () {
            this.ensureOneEmpty();
        },
        methods: {
            userUpdate (userEventData, pendingUser) {
                _.extendOwn(pendingUser, userEventData);
            },
            setPendingUserCompsRef(el) {
                if (el) {
                    this.pendingUserComps.push(el)
                }
            },
            loadPastedEmails () {
                let pastedEmails = this.$refs.manyEmails.value;
                pastedEmails = pastedEmails ? pastedEmails.trim() : '';
                if (pastedEmails === '') {
                    ScNotification.growlErrMsg('Please enter some emails.');
                } else {
                    if (pastedEmails.indexOf(',')) pastedEmails = pastedEmails.split(',').join('\n');
                    this.loadFromCsv(pastedEmails);
                    this.pasteEmailsEnabled = false;
                }
            },
            loadCsv () {
                let vm = this;
                let files = this.$refs.importInput.files;
                if (!files.length) {
                    ScNotification.growlErrMsg('Please choose a CSV file.');
                    return;
                }
                let file = files[0];
                if (file.name && !file.name.includes('.csv')) {
                    ScNotification.growlErrMsg('File selected did not end with .csv, please try again with a csv file.');
                    return;
                }
                if (file.size > (1024 * 1024)) {
                    ScNotification.growlErrMsg('Your CSV file is too big. Max size 1MB.');
                    return;
                }
                let reader = new FileReader();
                reader.onloadend = function (evt) {
                    if (evt.target.readyState === FileReader.DONE) { // DONE == 2
                        try {
                            vm.loadFromCsv(evt.target.result);
                            vm.csvImportEnabled = false;
                        } catch (err) {
                            ScNotification.growlErrMsg('Your CSV file contained invalid data');
                        }
                    }
                };
                reader.readAsText(file, "UTF-8");
            },
            loadFromCsv (csvData) {
                csvData = csvData.replace(/\r\n/g, "\n");
                csvData = csvData.replace(/\r/g, "\n");
                let csvObj = Papa.parse(csvData);
                let vm = this;
                _.each(csvObj.data, function (lineArr) {
                    if (lineArr.length === 0) return;
                    let user = {email: '', firstName: '', lastName: '', password: '', roleCode: '', groupNames: '',
                        userWorkspaceRef: null};
                    let fields = _.keys(user);
                    _.each(lineArr, function (value, index) {
                        if (index + 1 > fields.length) return;
                        user[fields[index]] = value ? value.trim() : '';
                    });
                    if (user.email.length === 0) return;
                    user.password2 = user.password;  // copy confirm password
                    user.roleCode = user.roleCode.toLowerCase();
                    if (user.roleCode === 'admin') user.roleCode = 'r:admin';
                    else if (user.roleCode === 'manager') user.roleCode = 'r:manager';
                    else if (user.roleCode === 'reporter') user.roleCode = 'r:reporter';
                    else if (user.roleCode === 'workshop') user.roleCode = 'r:editor'; // workshop=legacy name for editor
                    else if (user.roleCode === 'editor') user.roleCode = 'r:editor';
                    else user.roleCode = 'r:viewer';
                    if (user.groupNames !== '') {
                        //console.log('groupNames', user.groupNames);
                        user.groupNames = user.groupNames.split('|');  // split by the | char into an array
                        user.groupNames = _.map(user.groupNames, (n) => n ? n.trim() : '');  // trim strings
                        user.groupNames = _.reject(user.groupNames, (n) => !n || n === '');  // remove empty items
                    } else {
                        user.groupNames = [];
                    }
                    if (user.password !== '' || user.firstName !== '' || user.lastName !== '') {
                        user.sendInviteLink = false;
                    }
                    vm.users.push(user);
                });
                this.deleteAnyEmptyUsers();
            },
            deleteAnyEmptyUsers () {
                this.pendingUserComps.forEach((pendingUserComp) => pendingUserComp.deleteIfEmpty());
            },
            ensureOneEmpty () {
                let numNotDeleted = 0;
                this.users.forEach((user) => {  // now ensure at lease one empty not deleted, not done user
                    if (!user.deleted && !user.done) numNotDeleted++;
                });
                if (numNotDeleted === 0) this.addPendingUser();
            },
            startAddingUsers () {
                this.commitAttempted = true;
                if (!this.acceptTerms) return;
                let pendingUserCompQueue = [];
                let numValidationErrors = 0;

                this.pendingUserComps.forEach((userComp) => {
                    let error = userComp.prepareForCommit(this.newsletterOptIn, this.onboardingOptIn);  // validate all users first
                    if (error) numValidationErrors++;
                });

                this.pendingUserComps.forEach((userComp) => {
                    if (userComp.processing) pendingUserCompQueue.push(userComp);  // now save them all
                });

                let nextPro = () => {
                    let userComp = pendingUserCompQueue.shift();
                    if (userComp) {
                        userComp.commitWithPromise().then(nextPro);
                    }   else {
                        this.processingUsers = false;
                        this.ensureOneEmpty();
                    }
                };

                if (pendingUserCompQueue.length === 0 && numValidationErrors === 0) {
                    ScNotification.growlErrMsg('Whoa. Add at least one email first.');
                    this.ensureOneEmpty();
                }   else {
                    this.processingUsers = true;
                    nextPro();
                }
            },
            addPendingUser () {
                this.users.push({});
            }
        },
        beforeUpdate() {
            this.pendingUserComps = [];
        }
    };

</script>