<template>
    <div>
        <div class="card-body">
            <div v-if="user.userHasMultipleWorkspaces" class="alert alert-info">
                <ScIcon name="questionCircle"/>
                This user is part of more than one workshop.
                They must maintain their own email, name and password.
            </div>
            <div v-if="user.userPasswordManagedExternally" class="alert alert-info">
                <ScIcon name="questionCircle"/>
                This user's password is managed externally.
            </div>

            <form action="#" enctype="multipart/form-data" id="user-form" method="post">
                <div class="mb-3 row">
                    <label for="auth_user_email" class="col-4 text-end my-auto">
                        Email
                    </label>
                    <div class="col-6">
                         <input v-if="editing" class="form-control" id="auth_user_email"
                                name="email" type="text" maxlength="255" v-model.trim="email" @focus="clearError"
                                :disabled="savingUser"
                                autocomplete="sc-user-email" />
                         <div v-else class="text-break my-auto">
                             <strong><a :href="'mailto:' + user.email">{{ user.email }}</a></strong>
                         </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="auth_user_first_name" id="auth_user_first_name__label" class="col-4 text-end my-auto">Name</label>
                    <template v-if="editing">
                        <div class="col-3">
                            <input class="form-control" id="auth_user_first_name" name="firstName"
                                   :disabled="savingUser"
                                   type="text" maxlength="128" placeholder="First Name" v-model.trim="first_name" @focus="clearError" />
                        </div>
                        <div class="col-3">
                            <input class="form-control" id="auth_user_last_name" name="lastName"
                                   :disabled="savingUser"
                                   type="text" maxlength="128" placeholder="Last Name" v-model.trim="last_name" @focus="clearError" />
                        </div>
                    </template>
                    <div v-else class="col-4 text-break my-auto">
                        <strong v-if="(user.firstName !== '' || user.lastName !== '')">{{ user.firstName }} {{ user.lastName }}</strong>
                    </div>
                    <div v-if="!user.userHasMultipleWorkspaces" class="col-4 d-flex justify-content-end">
                        <button v-if="!editing && !deletingUser" class="btn btn-outline-secondary"
                                :disabled="!user.canEditUser"
                                @click.prevent="toggleEditForm">Edit Password and Details</button>
                    </div>
                </div>

                <div v-if="!user.userHasMultipleWorkspaces && !user.userPasswordManagedExternally">
                    <div v-if="user.userStatus !== 'invited'" class="mb-3 row">
                        <label for="auth_user_password" class="col-4 text-end my-auto">Password</label>
                        <template v-if="editing">
                            <div class="col-6">
                                <input class="form-control" id="auth_user_password" name="password" v-model.trim="pwd"
                                       :disabled="savingUser"
                                       type="password" maxlength="32" @focus="clearError" autocomplete="sc-user-password" />
                            </div>
                            <div class="col-6 offset-4 text-muted mt-1">
                                <small class="text-muted">
                                    <ScIcon name="questionCircle"/> If password is left empty it will not be changed.
                                    <br/><ScIcon name="questionCircle"/>
                                    Password must have 6 characters minimum, 32 max.
                                </small>
                            </div>
                        </template>
                        <div v-if="!editing" class="col-3 text-break my-auto">
                            <div class="my-auto"><strong>*******</strong></div>
                        </div>
                        <div v-if="!editing" class="col-5 d-flex justify-content-end">
                            <button class="btn btn-outline-secondary" :disabled="!user.canEditUser"
                               @click.prevent="showPwResetModal=true">Generate Reset Password Code</button>
                        </div>
                    </div>
                    <div v-if="editing" class="mb-3 row">
                        <label for="auth_user_cpassword" class="col-4 text-end my-auto">Confirm Password</label>
                        <div class="col-6">
                            <input class="form-control" id="auth_user_cpassword" name="password_two" v-model.trim="confirm_pwd"
                                   :disabled="savingUser"
                                   type="password" maxlength="32" @focus="clearError" autocomplete="sc-user-new-password"/>
                        </div>
                    </div>
                    <div v-if="!editing && user.userStatus === 'invited'" class="offset-4 col-8 alert alert-info">
                        <ScIcon name="questionCircle"/>
                        User can set their own name and password, when they click the link in the invitation that was emailed to them.
                    </div>
                </div>

                <div v-if="editing" class="mb-3 row">
                    <div class="col-4 text-end"></div>
                    <div class="col-6">
                        <div v-if="error" id="form-error" class="alert alert-danger">{{error}}</div>
                        <button class="btn btn-primary-orange fw-bold me-2" @click.prevent="saveData()"
                                :disabled="savingUser">
                            <ScIcon v-if="savingUser" name="spinnerFW" class="mx-3" />
                            <span v-else>Done</span>
                        </button>
                        <a v-if="!savingUser" href="#" class="btn btn-link" @click.prevent="toggleListView">Cancel</a>
                    </div>
                </div>
            </form>
        </div>

        <div class="row">
             <div v-if="!editing" class="col ms-2">
                 <router-link :to="{ name: 'reporting-analytics', params: { workshopId: mainMxCurrentWorkshopId }, query: { uid: Number(user.id) }}" class="btn btn-link">
                     <strong><ScIcon name="chartLineFW" class="me-1"/> User Analytics</strong>
                 </router-link>

                 <router-link :to="{ name: 'reporting-activity', params: { workshopId: mainMxCurrentWorkshopId }, query: { uid: Number(user.id) }}" class="btn btn-link">
                     <strong><ScIcon name="university" class="me-1"/> Activity Log</strong>
                 </router-link>
            </div>

            <div class="col text-end mb-4">
                <button class="btn btn-outline-secondary me-4"
                        :disabled="deletingUser || !user.canEditUser" @click.prevent="removeUser()">
                    Remove User <ScIcon v-if="deletingUser" name="spinnerFW" />
                </button>
            </div>

        </div>

        <UserResetPasswordConfirmModal
                v-if="showPwResetModal"
                :userId="user.id"
                @modal-was-hidden="showPwResetModal=false"></UserResetPasswordConfirmModal>
    </div>
</template>

<script>

    import $ from "jquery";
    import ScCommonUtil from "../../shared/common/ScCommonUtil";
    import ScIcon from "../../shared/common/ScIcon.vue";
    import MainAppMixin from "../MainAppMixin";
    import ScConfirmModal2 from "../../shared/common/ScConfirmModal2.vue";
    import UserResetPasswordConfirmModal from "./UserResetPasswordConfirmModal.vue";
    import ScNotification from "../../shared/common/ScNotification.vue";

    export default {
        name: "UserDetails",
        emits: ['user-details-changed'],
        mixins: [ MainAppMixin ],
        components: { UserResetPasswordConfirmModal, ScIcon },
        props: {
            user: {type: Object, default: null}
        },
        data () {
            return {
                editing: false,
                error: null,
                email: '',
                first_name: '',
                last_name: '',
                pwd: '',
                confirm_pwd: '',
                savingUser: false,
                deletingUser: false,
                showPwResetModal: false
            };
        },
        mounted: function() {
            //console.log('mounted');
            this.email = this.user.email;
            this.first_name = this.user.firstName;
            this.last_name = this.user.lastName;
        },
        methods: {
            toggleEditForm () {
                this.editing = true;
            },
            toggleListView () {
                this.error = null;
                this.editing = false;
            },
            saveData () {
                if (!this.validateUser()) {
                    this.error = null;
                    this.saveUser();
                }
            },
            saveUser() {
                this.savingUser = true;
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_update_user_v2',
                    data: {
                        workspace_id: this.mainMxCurrentWorkshopId,
                        user_id: this.user.id,
                        email: this.email,
                        first_name: this.first_name,
                        last_name: this.last_name,
                        password: this.pwd
                    }
                }).done((data) => {
                    if (data.status === 'ok') {
                        ScNotification.growlSuccessMsg('Saved');
                        this.pwd = '';
                        this.confirm_pwd = '';
                        this.editing = false;
                        this.$emit('user-details-changed', {firstName: this.first_name, lastName: this.last_name, email: this.email})
                    }   else {
                        ScNotification.growlErrMsg(data.message);
                    }
                    this.savingUser = false;

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'saving user details');
                    this.savingUser = false;
                });
            },
            removeUser () {
                ScConfirmModal2.modal('Are you sure you want to remove this user?').then(() => {
                    this.deleteUserConfirmed();
                }, () => {});
            },
            deleteUserConfirmed() {
                this.deletingUser = true;
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_delete_user_v2',
                    data: { workspace_id: this.mainMxCurrentWorkshopId, userId: this.user.id }

                }).done((data) => {
                    if (data.status === 'success')
                        this.$router.push({ name: 'user-list', params: { workshopId: this.mainMxCurrentWorkshopId } });
                    this.deletingUser = false;

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'deleting user');
                    this.deletingUser = false;
                });
            },
            clearError() {
                this.error = null;
            },
            validateUser () {
                this.error = null;
                if (!this.first_name)
                    this.error = 'First name cannot be empty';
                else if (!this.email)
                    this.error = 'Email can not be empty';
                else if (!ScCommonUtil.validateEmail(this.email))
                    this.error = "Invalid email";

                if (!this.error && this.pwd !== '' ) {  // only validate password if it's not empty
                    if (this.confirm_pwd === '') {
                        this.error = 'Confirm Password can not be empty';
                    } else {
                        if (this.pwd.length < 6)
                            this.error = "Please enter a password that is at least 6 characters long";
                        else if (this.pwd !== this.confirm_pwd)
                            this.error = 'Passwords do not match';
                    }
                }

                if (this.error)
                    return true;

                return false;
            }
        }
    }

</script>
