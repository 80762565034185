<template>
<ScNormalPageLayout :headingPart1="selectedGroupId ? 'Group':'User'" :headingPart2="selectedGroupId ? 'details':'directory'" :submenu="selectedGroupId ? null : 'user'">
    <div class="container mb-4" v-if="!showManageUserGroupModal">
        <div class="col-10 offset-1 mb-5">

            <div v-if="isLoading" class="text-center mt-5">
                <ScIcon name="spinnerMedium" class="m-5 text-muted"/>
            </div>

            <template v-else >
                <div v-if="selectedGroupId" class="m-3 mb-4 row g-0 justify-content-between align-items-center">
                    <div class="col-auto mb-auto" v-if="$route.name === 'user-list-unassigned'">
                        <h4><router-link :to="{name: 'user-list', params: { workshopId: mainMxCurrentWorkshopId }}">
                        <ScIcon name="angleLeft" class="me-2"/>Users</router-link></h4>
                    </div>
                    <div class="col-auto mb-auto" v-else>
                        <h4><router-link :to="{name: 'user-group-list', params: { workshopId: mainMxCurrentWorkshopId,
                                groupId: 0 }}">
                        <ScIcon name="angleLeft" class="me-2"/>Groups</router-link></h4>
                    </div>

                        
                    <div class="col mx-4 text-center">
                        <h2><ScIcon name="users" class="me-2"/>
                            <template v-if="selectedGroupId > 0">{{ selectedGroupName }}</template>
                            <template v-if="selectedGroupId === -1"> Unassigned </template>
                        </h2>
                    </div>
                    <div class="col-auto mb-auto text-end delete-btn">
                        <button v-if="selectedGroupId > 0" @click.prevent="deleteEmptyGroup"
                                v-sc-tooltip="userCount === 0 ? 'Delete group' : 'Please remove all users from group to delete.'"
                                type="button" :class="['text-muted btn', userCount > 0 ? 'disabled' : '']">
                            <ScIcon name="trashAltFW"/>
                        </button>
                    </div>
                </div>

                <div class="row g-0 justify-content-between mt-3 mb-3">
                    <div class="col-12 d-flex" v-if="selectedGroupId === 0 || $route.name === 'user-list-unassigned'">
                        <router-link
                                v-if="!wsUserLimitReached"
                                :to="{ name: 'user-add', params: { workshopId: mainMxCurrentWorkshopId }}"
                                     class="btn btn-primary-orange fw-bold py-2"><ScIcon name="user" class="me-2"/>
                            Add Users</router-link>
                        <div v-if="wsUserLimitReached" class="alert alert-warning">
                            User limit reached for plan: {{wsSubscriptionName}}. Remove some users first, or subscribe.
                        </div>
                        <div class="row mx-1" v-if="!manageUserGroupMode">
                            <button type="button" class="btn btn-outline-secondary" 
                                @click.prevent="toggleManageUserGroupMode"><ScIcon name="userFriends"/> Manage Users Group</button>
                        </div>
                        <div class="row-3" v-else>
                            <button type="button" class="btn btn-outline-secondary mx-1" 
                                @click.prevent="triggerEditManageUserGroup"><ScIcon name="userFriends"/> Edit Users Group</button>
                            <button type="button" class="btn btn-outline-secondary " 
                                @click.prevent="toggleManageUserGroupMode"> Cancel</button>
                        </div>
                    </div>
                </div>

                <div  class="row g-0 align-items-center justify-content-between mt-1 mb-3">
                     <div :class="[ selectedGroupId > 0 && usersNotInSelectedGroup ? 'col-12 mb-2' : 'col-5']">
                        <template v-if="userCount === 0"><b>0</b> Users</template>
                        <template v-if="userCount === 1"><b>1</b> User</template>
                        <template v-if="userCount > 1"><b> {{ userCount }}</b> Users</template>

                        <a href="#" @click.prevent="showPresAccessListModal=true" class="show-group-access"
                           v-sc-tooltip="'Show list of presentations with \'' + selectedGroupName + '\' in \'Limit access\''">
                            <ScIcon name="lock" class="ms-3"/> Presentation Access</a>
                     </div>

                     <div :class="['d-flex', selectedGroupId > 0 ? 'col-12 mt-2' : 'col']">
                         <div v-if="selectedGroupId > 0 && usersNotInSelectedGroup" class="col-5 ps-0 my-auto">
                             <ScSelectWithSearchAndGroups :items="[usersNotInSelectedGroup]"
                                                          :placeholder="'Add user to group'"
                                                          :emptyAfterSelection="true"
                                                          :maxHeightClass="400"
                                                          @sc-select-item="addToGroup">
                             </ScSelectWithSearchAndGroups>
                        </div>
                        <div class="ms-auto me-2" v-if="['user-list', 'user-list-unassigned'].includes($route.name)">
                            <router-link :to="{name: 'user-list-unassigned', params: { workshopId: mainMxCurrentWorkshopId,
                                   groupId: -1 }}" class="btn btn-link text-center mx-2 ms-auto"
                             v-sc-tooltip="'Show users with no groups'">Unassigned</router-link>
                        </div>
                        <div class="ms-auto me-2 text-end">
                            <button v-if="userCount > 0" class="btn btn-link text-muted ms-1" @click.prevent="generateCsv"
                                    v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV </button>
                        </div>

                        <div class="me-2">
                            <div class="customSelectArrow">
                           <select id="users-sorting" name="sort" class="form-control bg-white" v-model="sorting" v-sc-tooltip="'Sorting'">
                                <option value="name-asc" :selected="(sorting === 'name-asc') ? 'selected' : ''" >Name (A-Z)</option>
                                <option value="name-desc" :selected="(sorting === 'name-desc') ? 'selected' : ''" >Name (Z-A)</option>
                                <option value="email-asc" :selected="(sorting === 'email-asc') ? 'selected' : ''" >Email (A-Z)</option>
                                <option value="email-desc" :selected="(sorting === 'email-desc') ? 'selected' : ''" >Email (Z-A)</option>
                                <option value="admin-asc" :selected="(sorting === 'admin-asc') ? 'selected' : ''" >Role (A-Z)</option>
                                <option value="admin-desc" :selected="(sorting === 'admin-desc') ? 'selected' : ''" >Role (Z-A)</option>
                                <option value="status-desc" :selected="(sorting === 'status-desc') ? 'selected' : ''" >Invited users first</option>
                                <option value="status-asc" :selected="(sorting === 'status-asc') ? 'selected' : ''">Invited users last</option>
                                <option value="id-desc" :selected="(sorting === 'id-desc') ? 'selected' : ''">Most recently added</option>
                            </select>
                        </div>
                        </div>

                        <div class="input-group mb-auto" style="width: 170px">
                            <input type="search" placeholder="Search" class="form-control" v-model="searchValue" />
                            <button class="btn btn-outline-secondary"><ScIcon name="search"/></button>
                        </div>
                    </div>
                </div>

                <div v-if="searchValue && userCount === 0" class="p-2 my-5 text-center text-muted"><em>No matching users</em></div>
                <div v-else-if="userCount === 0 && selectedGroupId < 0" class="p-2 my-5 text-center text-muted"><em>No users unassigned (all users are in at least one group)</em></div>
                <div v-else-if="userCount === 0" class="p-2 my-5 text-center text-muted"><em>Let's get some users in here!</em></div>

                <User :user="sortedUser"
                      :groups="groupsForUser"
                      :selectedGroupId="selectedGroupId"
                      :selectedGroupName="selectedGroupName"
                      :manageUserGroupMode="manageUserGroupMode"
                      @update-user-group-user-list="updateManageUserGroupList"
                      @remove-from-group="removeFromGroup" ></User>

                <PresentationAccessListModal v-if="showPresAccessListModal" :workspaceId="mainMxCurrentWorkshopId"
                      :groupId="selectedGroupId" :groupName="selectedGroupName"
                      @modal-was-hidden="showPresAccessListModal=false"></PresentationAccessListModal>

            </template>
        </div>
    </div>
    <ManageUserGroup v-else
        :selectedGroupId="getCommonUserGroups"
        :loadedGroups="loadedGroups"
        :selectedUsers="manageUserGroupUserList">
    </ManageUserGroup>
</ScNormalPageLayout>
</template>

<script>
    import $ from 'jquery';
    import _ from 'underscore'; // filter, findWhere, isEmpty, sortBy, where
    import {format} from 'date-fns';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import PresentationAccessListModal from './PresentationAccessListModal.vue';
    import ScSelectWithSearchAndGroups from '../global/ScSelectWithSearchAndGroups.vue';
    import ManageUserGroup from './ManageUserGroup.vue';
    import MainAppMixin from '../MainAppMixin';
    import User from './User.vue';
    import Papa from 'papaparse';
    import ScCsvUtil from "../global/ScCsvUtil";


    let _getShowName = function(userObj) {
        let user = userObj.auth_user;
        if (userObj.displayable_name === user.email) return user.email;
        return userObj.displayable_name + ' <' + user.email + '>';
    };

    let DataManager = function () {
        return {
            getUserListing (vm) {
                vm.isLoading = true;
                vm.loadedGroups.splice(0);
                vm.loadedUsers.splice(0);
                vm.loadedUserGroups.splice(0);

                $.ajax({
                    type: 'GET', url: '/main/users/ajax_user_listing',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId }

                }).done(function (data) {
                    //console.log(data);
                    if (data !== undefined && data.all_groups !== undefined)
                        for (let g in data.all_groups) {
                            vm.loadedGroups.push(data.all_groups[g]);
                        }

                    if (!_.isEmpty(data) && !_.isEmpty(data.all_users)) {
                        for (let g in data.all_users) {
                            let u = data.all_users[g];
                            u.id = u.auth_user.id;
                            u.showName = _getShowName(u);
                            vm.loadedUsers.push(u);
                        }
                    }
                    if (!_.isEmpty(data) && !_.isEmpty(data.all_user_groups)) {
                        for (let ug in data.all_user_groups) {
                            if (data.all_user_groups[ug])
                                vm.loadedUserGroups.push(data.all_user_groups[ug]);
                        }
                    }
                    vm.wsUserLimitReached = data.user_limits ? data.user_limits.user_limit_reached : false;
                    vm.wsSubscriptionName = data.user_limits ? data.user_limits.plan_name : null;

                }).always(function () {
                    vm.isLoading = false;

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'loading user listing');
                });
            },
            deleteUserFromGroup (vm, userId) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_update_usergroups_v2',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        selected: false,
                        user_group_id: vm.selectedGroupId,
                        user_id: userId
                    }
                }).done(function(data) {
                    //console.log(data);
                    ScNotification.growlSuccessMsg('User removed from group.');
                    if (data.status === 'ok')
                        vm.loadedUserGroups = _.filter(vm.loadedUserGroups, function(lug) { return lug.user_id !==  userId });

                }).fail(function(xhr) {
                    ScNotification.growlErrMsg('Error saving. ' + xhr.status);
                });

            },
            saveUserToGroup (vm, userId) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_update_usergroups_v2',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        user_id: userId,
                        user_group_id: vm.selectedGroupId,
                        selected: true
                    }

                }).done(function(data){
                    //console.log(data);
                    if (data.status !== 'ok')
                        ScNotification.growlErrMsg(data.details);
                    else
                        vm.loadedUserGroups.push({ group_id: vm.selectedGroupId, user_id: userId })

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'adding');
                });

            },
            deleteGroup (vm, deleteId) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_delete_group_v2',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId, group_id: Number(deleteId) }

                }).done(function (data) {
                    if (data.status === 'error')
                        ScNotification.growlErrMsg(data.details);
                    else
                        vm.$router.push(
                            { name: 'user-group-list', params: { workshopId: vm.mainMxCurrentWorkshopId, groupId: 0 }});

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'deleting group');
                });
            },
        }
    };
    let _dataManager = null;

    export default {
        name: "UserList",
        mixins: [ MainAppMixin ],
        components: { User, ScSelectWithSearchAndGroups, ScNormalPageLayout, ScIcon, PresentationAccessListModal, ManageUserGroup},
        data () {
            return {
                sorting : 'name-asc',
                searchValue: null,
                isLoading: false,
                loadedGroups: [],
                loadedUsers: [],
                loadedUserGroups: [],
                wsUserLimitReached: false,
                wsSubscriptionName: null,
                showPresAccessListModal: false,
                manageUserGroupMode: false,
                manageUserGroupUserList: [],
                showManageUserGroupModal: false
            }
        },
        mounted () {
            console.log(this.$route);
            _dataManager = new DataManager();
            _dataManager.getUserListing(this);
        },
        computed: {
            getCommonUserGroups() {
                const selectedUserCount = this.manageUserGroupUserList.length;
                if (selectedUserCount < 1) {
                    return [];
                }
                let commonUserGroupIdsCounter = {};
                let commonUserGroupIds = [];

                this.loadedUserGroups.forEach((userUserGroup)=> {
                    if (this.manageUserGroupUserMap[userUserGroup.user_id]) {
                        if (commonUserGroupIdsCounter[userUserGroup.group_id]) {
                            commonUserGroupIdsCounter[userUserGroup.group_id] = commonUserGroupIdsCounter[userUserGroup.group_id] + 1
                        } else {
                            commonUserGroupIdsCounter[userUserGroup.group_id] = 1
                        }
                    }
                });
                for (const [key, value] of Object.entries(commonUserGroupIdsCounter)) {
                    // if the count for this group is equal to the selected user count
                    // consider the group as common across selected users
                    if (value === selectedUserCount) {
                        commonUserGroupIds.push(key);
                    }
                }
                return commonUserGroupIds;
            },
            loadedUsersMap() {
                let object = {};
                this.loadedUsers.map((user)=> {
                    object[user.id] = user
                })
                return object
            },
            manageUserGroupUserMap() {
                let map = {};
                this.manageUserGroupUserList.map((user)=> {
                    map[user.id] = user
                })
                return map
            },
            searchedUser() {
                if (this.searchValue) {
                    let that = this;
                    let filtered = _.filter(this.userForSelectedGroup, function (u) {
                        let fullName = u.displayable_name + ' ' + u.auth_user.email + ' ' + u.userworkspace.user_workspace_ref;
                        if (fullName.toLowerCase().includes(that.searchValue.toLowerCase())) {
                            return true;
                        }
                    });
                    return filtered;
                }

                return this.userForSelectedGroup;
            },
            userForSelectedGroup () {
                if (this.selectedGroupId === 0)
                    return this.loadedUsers;

                if (this.selectedGroupId === -1) {
                    let userIds = [];
                    for (let entry of this.loadedUserGroups) {
                        if (!userIds.includes(entry.user_id))
                            userIds.push(entry.user_id);
                    }
                    return _.filter(this.loadedUsers, function (u) { if (!userIds.includes(u.auth_user.id)) return true;});
                }

                let groups =_.where(this.loadedUserGroups, {group_id: this.selectedGroupId});
                let users = [];
                if (groups)
                    for (let g of groups) {
                        let user = _.filter(this.loadedUsers, function (u) { if (g.user_id === u.auth_user.id) return true; });
                        if (user && user.length > 0) {
                            user[0].user_groups = g;
                            users.push(user[0]);
                        }
                    }
                return users;
            },
            usersNotInSelectedGroup() {
                let that = this;
                let alreadyInGroup =_.filter(this.loadedUserGroups, function(lug){ if (that.selectedGroupId === lug.group_id) return true;});
                let notInGroup = this.loadedUsers;

                for (let g of alreadyInGroup) {
                    notInGroup = _.filter(notInGroup, function (u) { return u.auth_user.id !== g.user_id })
                }
                return notInGroup;
            },
            groupsForUser () {
                let matched = [];
                if (!_.isEmpty(this.loadedUsers) && !_.isEmpty(this.loadedUserGroups) && !_.isEmpty(this.loadedGroups)) {
                    for (let u in this.loadedUsers) {
                        if (this.loadedUsers[u]) {
                            u = this.loadedUsers[u];
                            let uInG = _.filter(this.loadedUserGroups, function(e) { if (e.user_id === u.auth_user.id) return true; });
                            for (let e of uInG) {
                                let group = _.findWhere(this.loadedGroups, {id: e.group_id});
                                if (group)
                                    e.groupName = group.name;
                            }
                            matched.push({userId: u.auth_user.id, groups: uInG})
                        }
                    }
                }
                return matched;
            },
            userCount () {
                return this.searchedUser.length;
            },
            sortedUser () {
                let sort = this.sorting;
                let reverseSort = false;
                if (sort.includes('-desc'))
                    reverseSort = true;

                sort = sort.replace('-desc', '').replace('-asc', '');

                let that = this;
                let sortedAllUsers = _.sortBy(this.searchedUser, function(user) {
                    let defaultSortStr = user.displayable_name.toLowerCase();
                    if (sort === 'email') {
                        defaultSortStr = user.auth_user.email.toLowerCase();
                    } else if (sort === 'admin') {
                        defaultSortStr = user.role_code + ' ' + defaultSortStr;
                    } else if (sort === 'status') {
                        defaultSortStr = user.status + ' ' + defaultSortStr;
                    } else if (sort === 'id') {
                        if (that.selectedGroupId === 0 || that.selectedGroupId === -1)
                            defaultSortStr = user.userworkspace.id + ' ' + defaultSortStr;
                        // sort recently added to group if group selected
                        for (let su of that.searchedUser) {
                            if (su.id === user.auth_user.id && su.user_groups && su.user_groups.group_id === that.selectedGroupId){
                                defaultSortStr = su.user_groups.updated_date + ' ' + defaultSortStr;
                            }
                        }
                    }
                    return defaultSortStr;
                });

                if (reverseSort)
                    sortedAllUsers = sortedAllUsers.reverse();

                return sortedAllUsers;

            },
            selectedGroupId () {
                if (this.$route.params.groupId) {
                // if (this.$route.params.groupId && this.$route.name !== 'user-list-unassigned') {
                    return Number(this.$route.params.groupId);
                }
                return 0;
            },
            selectedGroupName () {
                 if (this.$route.params.groupId && this.loadedGroups.length > 0) {
                     let group = _.findWhere(this.loadedGroups, {id: Number(this.$route.params.groupId) });
                     if (group)
                         return group.name;
                 }
                 return 'All Users';
            }
        },
        methods: {
            toggleManageUserGroupModal() {
                const status = !this.showManageUserGroupModal;
                this.showManageUserGroupModal = status;
            },
            triggerEditManageUserGroup() {
                if (this.manageUserGroupUserList.length < 1) {
                    ScNotification.growlErrMsg('Please select at least one user');
                    return null;
                }
                this.toggleManageUserGroupModal()
            },
            updateManageUserGroupList(event) {
                this.manageUserGroupUserList = this.loadedUsers.filter((user)=> {
                    return event[user.id]
                })
            },
            toggleManageUserGroupMode() {
                const status = !this.manageUserGroupMode;
                this.manageUserGroupMode = status;
                this.manageUserGroupUserList = [];
            },
            removeFromGroup (user) {
                 ScConfirmModal2.modal('Are you sure you want to remove "' + user.displayable_name + '" from this group?').then(() => {
                     _dataManager.deleteUserFromGroup(this, user.auth_user.id);
                }, () => {});
            },
            addToGroup (user) {
                if (this.selectedGroupId)
                    _dataManager.saveUserToGroup(this, user.auth_user.id);
            },
            deleteEmptyGroup () {
                if (this.userCount > 0) return;
                ScConfirmModal2.modal('Are you sure you want to delete group "' + this.selectedGroupName + '"?').then(() => {
                    _dataManager.deleteGroup(this, this.selectedGroupId);
                }, () => {});
            },
            generateCsv () {
                let csvData = [['Email', 'Name', 'User Workshop Reference', 'Role', 'Status', 'Last day seen', 'Groups']];
                for (let user of this.searchedUser) {
                    let roleCodeToName = {'r:admin': 'Admin', 'r:manager': 'Manager', 'r:reporter': 'Reporter',
                        'r:editor': 'Editor', 'r:viewer': 'Viewer'};
                    let roleName = roleCodeToName[user.role_code] ? roleCodeToName[user.role_code] : '';
                    let cells = [
                        user.auth_user.email,
                        user.displayable_name,
                        user.userworkspace.user_workspace_ref ? user.userworkspace.user_workspace_ref : '',
                        roleName,
                        user.status,
                        (user.last_analytics_event_date ? format(new Date(user.last_analytics_event_date), 'yyyy-MM-dd') : '')
                    ];
                    let that = this;
                    let groups = _.filter(that.loadedUserGroups, function (ug) { if (ug.user_id === user.auth_user.id) return true; });
                    let sorted = groups.sort(function (a, b) { return a.groupName > b.groupName; });
                    let joined = [];
                    for (let s of sorted) {
                        joined.push(s.groupName);
                    }
                    cells.push(joined.join(' | '));
                    csvData.push(cells);
                }

                let finalCsvText = Papa.unparse(csvData);
                let groupName = (this.selectedGroupId) ? this.selectedGroupName.replace(/\s+/g, '-').toLowerCase() + '-' : '';
                let fileName = 'users-' + groupName + format(new Date(), 'yyyyMMddHHmm') + '.csv';
                ScCsvUtil.deliverCsv(finalCsvText, fileName);
            }

        }
    }
</script>

