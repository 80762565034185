<template>
    <div v-if="manageUserGroupModeComputed" class="row mx-0 py-3 border-bottom">
        <div class="col-1 my-auto" style="width: 3%" v-if="manageUserGroupModeComputed">
            <input type="checkbox" class="form-check-input float-end" name="selectAll" v-model="selectAll">
        </div>
        <div class="text-break" :class="{'col-6': manageUserGroupModeComputed, 'col-7': !manageUserGroupModeComputed}">
            {{ selectAllLabel }}
        </div>
        
    </div>
    <div v-if="user">
    <div v-for="(u, idx) of user"
         :title="(u.is_billing_exempt) ? 'Note - This user is not included in billing.' : ''" :key="'u' + idx"
         class="row mx-0 py-3 border-bottom">

        <div class="col-1 my-auto" style="width: 3%" v-if="manageUserGroupModeComputed">
            <input type="checkbox" class="form-check-input float-end" name="userGroupUserList"
                :value="u.id"  
                :checked="groupUserIdList[u.id]"
                @change.prevent="emitUserGroupList"
                v-model="groupUserIdList[u.id]">
        </div>
        <div class="text-break" :class="{'col-6': manageUserGroupModeComputed, 'col-7': !manageUserGroupModeComputed}">
            
            <div class="fw-bold">
                <ScIcon v-if="u.is_billing_exempt" name="wrench" class="me-1"
                   v-sc-tooltip="'Support User (not included in billing)'" />

                <template v-if="$route.params.groupId">
                    <router-link :to="{ name: 'user-view', params: { workshopId: workshopId,
                            userId: u.auth_user.id, groupId: $route.params.groupId }}">
                            {{ u.displayable_name }}</router-link>
                </template>
                <template v-else >
                    <router-link :to="{ name: 'user-view', params: { workshopId: workshopId, userId: u.auth_user.id }}">
                            {{ u.displayable_name }}</router-link>
                </template>

                <small class="text-muted fw-normal ms-1" v-if="u.userworkspace.user_workspace_ref">
                        ({{ u.userworkspace.user_workspace_ref }})</small>
            </div>

            <div v-if="u.auth_user.email !== u.displayable_name" class="mb-3">{{ u.auth_user.email }}</div>

            <router-link v-for="(entry,idx) of userGroups(u.auth_user.id)" :key="'ug' + idx" style="white-space: normal;"
                    :to="{name: 'user-group-user-list', params: { workshopId: mainMxCurrentWorkshopId,
                    groupId: entry.group_id }}" class="btn btn-outline-primary py-0 me-1 mb-1"
                    :class="u.auth_user.email === u.displayable_name ? 'mt-2' : ''">{{ entry.groupName }}</router-link>

        </div>

        <div class="col-2 my-auto">
            <template v-if="u.role_code === 'r:viewer'">
                <ScIcon name="userFW" class="h5 mb-0" title="Viewer"/> Viewer
            </template>
            <template v-else-if="u.role_code === 'r:editor'">
                <ScIcon name="userNinjaFW" class="h5 mb-0" title="Editor"/> Editor
            </template>
            <template v-else-if="u.role_code === 'r:manager'">
                <ScIcon name="userAstronautFW" class="h5 mb-0" title="Manager"/> Manager
            </template>
            <template v-else-if="u.role_code === 'r:reporter'">
                <ScIcon name="userBountyHunterFW" class="h5 mb-0" title="Reporter"/> Reporter
            </template>
            <template v-else-if="u.role_code === 'r:admin'">
                <ScIcon name="userCowboyFW" class="h5 mb-0" title="Admin"/> Admin
            </template>
        </div>

        <div class="col-3 my-auto">
            <button v-if="selectedGroupId > 0 && selectedGroupName" class="btn btn-close text-muted float-end"
                    @click.prevent="emitRemoveUser(u, $event)"
                    v-sc-tooltip="'Remove user from group ' + selectedGroupName"></button>

            <template v-if="u.status === 'invited'">
                <ScIcon name="circleFW" class="h5 mb-0 text-warning" title="Viewer"/>
                Invited
                <a v-show="!invSent.includes(u.auth_user.id)" title="Resend invitation" href=""
                   @click.prevent="resendInvitation(u.auth_user.id)">(resend)</a>
                <br/>
            </template>
            <router-link v-if="u.last_analytics_event_date" v-sc-tooltip="'Last day seen'" class="text-muted"
                         :to="{ name: 'reporting-analytics', params: { workshopId: workshopId }, query: { uid: u.auth_user.id }}">
                <ScIcon name="chartLineFW"/>&nbsp;
                {{ fmtDate(u.last_analytics_event_date) }}
            </router-link>

        </div>
    </div>



        </div>
</template>

<script>
    import $ from 'jquery';
    import _ from 'underscore'; // filter
    import {format} from 'date-fns';
    import MainAppMixin from '../MainAppMixin';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    let DataManager = function () {
        return {
            sendInvitation (userId, vm) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_resend_invite_v2',
                    data: { workspace_id: vm.workshopId, user_id: userId }

                }).done(function (data) {
                    if (data.status === 'error') {
                        ScNotification.growlErrMsg(data.details);
                        vm.invSent = vm.invSent.filter(item => item !== userId);
                    }
                    else
                        ScNotification.growlSuccessMsg(data.details);

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'resending invitation');
                });
            }
        };
    };
    let _dataManager = null;

    export default {
        name: "User",
        emits: ['remove-from-group', 'update-user-group-user-list'],
        mixins: [ MainAppMixin ],
        components: {ScIcon},
        props: {
            user: Array,
            groups: Array,
            selectedGroupId: Number,
            selectedGroupName: String,
            manageUserGroupMode: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                invSent: [],
                groupUserIdList: {},
                selectAll: false
            }
        },
        mounted () {
            this.invSent.splice(0);
            _dataManager = new DataManager();
        },
        computed: {
            workshopId () {
                return this.$store.state.user.currentWorkshopId;
            },
            manageUserGroupModeComputed () {
                return this.manageUserGroupMode;
            },
            selectAllLabel () {
                return !this.selectAll ? 'Select all' : 'Deselect all'
            },
        },
        watch: {
            manageUserGroupModeComputed(value, oldValue) {
                if (value !== oldValue) {
                    this.groupUserIdList = [];
                    this.selectAll = false;
                }
            },
            selectAll(value, oldValue) {
                if (value !== oldValue) {
                    let emit = false
                    if (!value && this.groupUserIdList.length > 0) {
                        emit = true
                        this.groupUserIdList = [];
                    } else if (value) {
                        emit = true
                        this.user.map((user) => {
                            this.groupUserIdList[user.id] = true
                        });
                    }
                    if (emit) {
                        this.emitUserGroupList();
                    }
                }
            }
        },
        methods: {
            fmtDate (date) {
                return format(new Date(date), 'MMMM dd, yyyy');
            },
            userGroups (userId) {
                let filter = _.filter(this.groups, function(g) { if (g.userId === userId) return true; });
                return filter && filter.length > 0 ? filter[0].groups : [];
            },
            resendInvitation (userId) {
                this.invSent.push(userId);
                _dataManager.sendInvitation(userId, this);
            },
            emitRemoveUser(u, e){
                e.target.blur();
                this.$emit('remove-from-group', u)
            },
            emitUserGroupList() {
                this.$emit('update-user-group-user-list', this.groupUserIdList)
            }
        }
    }
</script>
<style scoped>
    input[type="checkbox"]:hover {
        cursor: pointer;
    }
</style>
