<template>
<ScNormalPageLayout headingPart1="User" headingPart2="directory" submenu="user">
    <div class="container mb-4">

        <div v-if="isLoading" class="col-10 offset-1 my-5 text-center">
            <ScIcon name="spinnerMedium" class="m-5 text-muted"/>
        </div>

        <div v-else class="col-10 offset-1 mb-5">
            <div class="mt-3 mb-3">
                <template v-if="totalCount === 0"><b>0</b> Groups</template>
                <template v-if="totalCount === 1"><b>1</b> Group</template>
                <template v-if="totalCount > 1"><b> {{ totalCount }}</b> Groups</template>
            </div>


            <div class="w-100 mb-3 d-flex">
                <button class="btn btn-outline-secondary text-center" @click.prevent="createGroup">
                    <ScIcon name="plusFW" class="me-1"/>Create a new group</button>

                <router-link :to="{name: 'user-group-user-list', params: { workshopId: mainMxCurrentWorkshopId,
                                   groupId: -1 }}" class="btn btn-link text-center mx-2 ms-auto"
                             v-sc-tooltip="'Show users with no groups'">Unassigned</router-link>

                <div class="customSelectArrow me-2">
                    <select id="users-sorting" name="sort" class="form-control bg-white" v-model="sorting" v-sc-tooltip="'Sorting'">
                        <option value="name-asc" :selected="(sorting === 'name-asc') ? 'selected' : ''" >Name (A-Z)</option>
                        <option value="name-desc" :selected="(sorting === 'name-desc') ? 'selected' : ''" >Name (Z-A)</option>
                        <option value="id-desc" :selected="(sorting === 'id-desc') ? 'selected' : ''">Most recently added</option>
                    </select>
                </div>
                <div class="input-group" style="width: 200px">
                    <input type="search" placeholder="Search" class="form-control" v-model="searchValue" />
                    <button class="btn btn-outline-secondary input-group-text"><ScIcon name="search"/></button>
                </div>
            </div>
            <template v-if="selectedGroupId <= 0">
                <div v-for="entry in sorted" :key="'g' + entry.id" class="border-bottom">

                    <div class="d-flex my-1 p-2 align-items-center">

                        <div class="col-7 px-2 text-truncate text-primary">
                            <router-link :to="{name: 'user-group-user-list', params: { workshopId: mainMxCurrentWorkshopId,
                                    groupId: entry.id}}"><ScIcon name="users" class="me-3"/>{{ entry.name }}</router-link>
                        </div>
                        <div class="col-2 pe-2 text-truncate">
                            {{ userForGroup(entry) }} <ScIcon name="userFriends"/>
                        </div>
                        <div class="col-3 px-2 text-end">
                            <button type="button" class="btn btn-link px-2" v-sc-tooltip="'Edit group name'" @click.prevent="editGroup(entry)">
                                <ScIcon name="pencil" class="text-muted"/></button>
                            <button type="button" class="btn btn-link px-2" v-sc-tooltip="'Delete group'"
                                    @click.prevent="deleteGroup(entry)"><ScIcon name="trashAlt" class="text-muted"/></button>
                        </div>
                    </div>

                </div>
            </template>
            <UserNameGroupModal></UserNameGroupModal>
        </div>
    </div>
</ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // isEmpty, sortBy, findWhere, filter, where
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import MainAppMixin from '../MainAppMixin';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import UserNameGroupModal from './UserNameGroupModal.vue';


    let DataManager = function () {
        return {
            getUserListing (vm) {
                vm.isLoading = true;
                vm.loadedGroups.splice(0);
                vm.loadedUserGroups.splice(0);

                $.ajax({
                    type: 'GET', url: '/main/users/ajax_user_listing',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId }

                }).done(function (data) {
                    //console.log(data);
                    if (data !== undefined && data.all_groups !== undefined)
                        for (let g in data.all_groups) {
                            vm.loadedGroups.push(data.all_groups[g]);
                        }

                    if (!_.isEmpty(data) && !_.isEmpty(data.all_user_groups))
                        for (let ug in data.all_user_groups) {
                            if (data.all_user_groups[ug])
                                vm.loadedUserGroups.push(data.all_user_groups[ug]);
                        }


                }).always(function () {
                    vm.isLoading = false;

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'loading user listing');
                });
            },
            saveGroup (group, vm) {
                $.ajax({
                    type: "POST", url: '/main/users/ajax_save_usergroups_v2',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId, id: group.id, name: group.name }

                }).done(function(data) {
                    //console.log('saved Group', data);
                    if (data.status === 'error') {
                        ScNotification.growlErrMsg(data.details);
                        return;
                    }
                    let exists = _.findWhere(vm.loadedGroups, { id: Number(data.userGroupId) });
                    if (exists)
                        exists.name = group.name;
                    else
                        vm.loadedGroups.push({id: data.userGroupId, name: group.name});

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'adding');
                });
            },
            deleteGroup (vm, deleteId) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_delete_group_v2',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId, group_id: Number(deleteId) }

                }).done(function (data) {
                    //console.log(data);
                    if (data.status === 'error')
                        ScNotification.growlErrMsg(data.details);

                    else {
                        vm.$route.params.groupId = 0;
                        vm.showGroupList = true;
                        vm.loadedGroups = _.filter(vm.loadedGroups, function (g) { if (deleteId !== g.id) return true;})
                    }

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'deleting group');
                });
            },
        }
    };
    let _dataManager = null;


    export default {
        name: "UserGroupList",
        mixins: [ MainAppMixin ],
        components: { ScNormalPageLayout, ScIcon, UserNameGroupModal },
        data () {
            return {
                searchValue: null,
                sorting: 'name-asc',
                isLoading: true,
                loadedGroups: [],
                loadedUserGroups: [],
            }
        },
        mounted () {
            _dataManager = new DataManager();
            _dataManager.getUserListing(this);
        },
        computed: {
            selectedGroupId () {
                if (this.$route.params.groupId)
                    return this.$route.params.groupId;
                return 0;
            },
            totalCount () {
                return this.searched.length;
            },
            searched () {
                if (this.searchValue) {
                    let that = this;
                    let filtered = _.filter(this.loadedGroups, function (g) {
                        if (g.name.toLowerCase().includes(that.searchValue.toLowerCase())) { return true; }
                    });
                    return filtered;
                }
                return this.loadedGroups;
            },
            sorted () {
                let sort = this.sorting;
                let reverseSort = false;
                if (sort.includes('-desc'))
                    reverseSort = true;

                sort = sort.replace('-desc', '').replace('-asc', '');

                let sortedAll = _.sortBy(this.searched, function(group) {
                    let defaultSortStr = group.name.toLowerCase();

                    if (sort === 'id')
                        return group.id + ' ' + defaultSortStr;

                    return defaultSortStr;
                });

                if (reverseSort)
                    sortedAll = sortedAll.reverse();

                return sortedAll;
            }
        },
        methods: {
            userForGroup (group) {
                let userInGroup = _.where(this.loadedUserGroups, {group_id: group.id});
                if (userInGroup)
                    return userInGroup.length;
                return 0;
            },

            /* Modals */
            createGroup () {
                let that = this;
                UserNameGroupModal.modal({ groupId: -1, groupName: '' }, (callback) => {
                    _dataManager.saveGroup(callback, that);
                });
            },
            editGroup (group) {
                let that = this;
                UserNameGroupModal.modal({ groupId: group.id, groupName: group.name }, (callback) => {
                    _dataManager.saveGroup(callback, that);
                });
            },
            deleteGroup (group) {
                ScConfirmModal2.modal('Are you sure you want to delete group "' + group.name + '"?').then(() => {
                    _dataManager.deleteGroup(this, group.id);
                }, () => {});
            },

        }
    }
</script>
