<template>
    <div class="container mb-4">
        <div v-if="isLoading" class="text-center mt-5">
            <ScIcon name="spinnerMedium" class="m-5 text-muted"/>
        </div>
        <div v-else>
            <div class="col-10 offset-1">
                <div class="m-3 mb-4 row g-0 justify-content-between align-items-center">
                    <div class="col-auto mb-auto">
                        <h4>
                            <router-link :to="{ name: 'user-list', params: { workshopId: mainMxCurrentWorkshopId }, query: {ts: Date.now()}}">
                                <ScIcon name="angleLeft" class="me-2"/>Users
                            </router-link>
                        </h4>
                    </div>
                </div>
                <div  class="row g-0 align-items-center justify-content-between mt-1 mb-3">
                    <div class="col-12 mb-2" @click="isModalShown = true">
                        <a href="#" @click.prevent="showPresAccessListModal=true" class="show-group-access"
                            v-sc-tooltip="'Show list of selected users'">
                            <template v-if="userCount === 1"><b>1</b> Selected User</template>
                            <template v-else><b> {{ userCount }}</b> Selected Users</template>
                        </a>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="col-5 ps-0 my-auto">
                            <ScSelectWithSearchAndGroups :items="[groupsNotInSelectedGroup]"
                                :placeholder="'Add selected users to group'"
                                :emptyAfterSelection="true"
                                :maxHeightClass="400"
                                @sc-select-item="addToGroup">
                            </ScSelectWithSearchAndGroups>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-10 offset-1 mb-5">
                <div v-for="entry in selectedGroups" :key="'g' + entry.id" class="border-bottom">

                    <div class="d-flex my-1 p-2 align-items-center">

                        <div class="col-7 px-2 text-truncate text-primary">
                            <router-link :to="{name: 'user-group-user-list', params: { workshopId: mainMxCurrentWorkshopId,
                                    groupId: entry.id}}"><ScIcon name="users" class="me-3"/>{{ entry.showName }}</router-link>
                        </div>
                        <div class="col-3 px-2 text-end">
                            <button type="button" class="btn btn-close" v-sc-tooltip="'Delete selected users from group'"
                                    @click.prevent="deleteGroup(entry)"></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <teleport to=".sc-modals-anchor">
            <div v-if="isModalShown" ref="scModal" v-vb-is:modal.show
                @vb-hidden-bs-modal="isModalShown=false" class="modal fade" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-bottom-0">
                            <button class="btn-close" data-bs-dismiss="modal"></button>
                            <h4 class="modal-title w-100 text-center">Selected Users</h4>
                        </div>
                        <div class="modal-body pt-0 px-0">
                            <div class="row g-0">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" 
                                        v-for="user in selectedUsers"
                                        :key="user.id"> 
                                        {{user.displayable_name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
    import $ from 'jquery';
    import MainAppMixin from '../MainAppMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScSelectWithSearchAndGroups from '../global/ScSelectWithSearchAndGroups.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';

    let DataManager = function () {
        return {
            addGroupToUsers(vm, group, userIds) {
                vm.isLoading = true
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_bulk_add_usergroup',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        user_group_id: group.id,
                        user_ids: userIds.join(',')
                    }
                }).done(function(data) {
                    const userLabel = (userIds.length > 1) ? 'Users' : 'User'
                    ScNotification.growlSuccessMsg(`${userLabel} added to group`);
                    if (data.status === 'ok') {
                        vm.selectedGroups.push(group)
                    }
                    vm.isLoading = false
                }).fail(function(xhr) {
                    vm.isLoading = false
                    ScNotification.growlErrMsg('Error saving. ' + xhr.status);
                });
            },
            deleteGroupToUsers(vm, group, userIds) {
                vm.isLoading = true
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_bulk_delete_usergroup',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        user_group_id: group.id,
                        user_ids: userIds.join(',')
                    }
                }).done(function(data) {
                    const userLabel = (userIds.length > 1) ? 'Users' : 'User'
                    ScNotification.growlSuccessMsg(`${userLabel} removed from group`);
                    if (data.status === 'ok') {
                        vm.selectedGroups = vm.selectedGroups.filter((selectedGroup)=> {
                            return selectedGroup.id != group.id
                        })
                    }
                    vm.isLoading = false
                }).fail(function(xhr) {
                    vm.isLoading = false
                    ScNotification.growlErrMsg('Error saving. ' + xhr.status);
                });
            }
        }
    }

    let _dataManager = null;

    export default {
        name: "ManageUserGroup",
        mixins: [ MainAppMixin ],
        components: { ScIcon, ScSelectWithSearchAndGroups },
        emits: [],
        props: {
            selectedUsers: Array,
            selectedGroupId: Array,
            loadedGroups: Array
        },
        data () {
            return {
               selectedGroups: [],
               isLoading: false,
               isModalShown: false
            }
        },
        methods: {
           transformGroupData (group) {
                return {
                    id: group.id,
                    showName: group.name,
                    list_id: group.list_id
                };
           },
           deleteGroup (group) {
                _dataManager.deleteGroupToUsers(this, group, this.userIds)
           },
           addToGroup (group) {
                _dataManager.addGroupToUsers(this, group, this.userIds)
           }
        },
        mounted() {
            _dataManager = new DataManager();
            this.selectedGroupId.forEach((groupId)=> {
                this.loadedGroups.some((group)=> {
                    if (groupId == group.id) {
                        this.selectedGroups.push(this.transformGroupData(group))
                        return true;
                    }
                    return false;
                })
            });
        },
        computed: {
            userCount() {
                return this.selectedUsers.length;
            },
            userIds() {
                return this.selectedUsers.map(user => user.id);
            },
            groupsNotInSelectedGroup() {
                let groupsToShowInDropDown = [];
                this.loadedGroups.map((group)=> {
                    let matchedGroup = false;
                    this.selectedGroups.forEach((selectedGroup)=> {
                        if (group.id == selectedGroup.id) {
                            matchedGroup = true;
                        }
                    });
                    if (!matchedGroup) {
                        groupsToShowInDropDown.push(this.transformGroupData(group));
                    }
                });
                return groupsToShowInDropDown;
            }
        }

    }
</script>