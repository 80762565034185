<template>
    <div class="row justify-content-end mt-2 mb-3">
        <div v-if="userId" class="col text-end">
            <div class="row">
                <label v-if="!isEditing && hasReference" class="col-4 text-end my-auto">User Workshop Reference</label>

                <div v-if="!isEditing && hasReference" class="col text-start  my-auto">{{userWsRef}}</div>

                <div class="col text-end">
                    <button class="btn btn-link me-2 my-auto" data-bs-toggle="collapse" aria-expanded="false" @click.prevent="toggleIsEditing"
                            data-bs-target="#userAttributesContainer" aria-controls="userAttributesContainer">

                        <template v-if="!hasReference">Add User Workshop Reference
                            <ScIcon :name="!isCollapsed ? 'angleDown' : 'angleUp'" class="ms-2"/>
                        </template>
                        <template v-else >Edit User Workshop Reference
                            <ScIcon v-if="isEditing" name="angleUp" class="ms-2"/>
                        </template>

                    </button>

                </div>
            </div>

            <div class="collapse" id="userAttributesContainer" ref="userAttsCont">
                <form class="justify-content-center mt-3">
                    <div class="mb-3 row">
                        <label for="addUserAttr" id="addUserAttrLabel" class="col-4 text-end my-auto">User Workshop Reference</label>
                        <div class="col-6">
                            <input class="form-control" id="addUserAttr" name="userAttr" type="text" maxlength="250"
                                   :disabled="!canEditUser"
                                 placeholder="e.g. Company Name, Site Number" v-model="userWsRef" />
                        </div>
                        <div class="col">
                            <button @click.prevent="updateAttributes" :disabled="!canEditUser" class="btn btn-outline-secondary">
                                <template v-if="!hasReference">Add</template>
                                <template v-else >Save</template>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

    import $ from 'jquery';
    import {Collapse} from 'bootstrap';
    import MainAppMixin from '../MainAppMixin';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    let DataManager = function () {
        return {
            saveUserReference (vm) {
                $.ajax({
                    type: 'POST', url: '/main/users/ajax_update_user_workshop_reference',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        user_id: vm.userId,
                        user_workspace_ref: vm.userWsRef
                    }

                }).done(function (data) {
                    //console.log(data)
                    if (data.status === 'ok') {
                        if (vm.$refs.userAttsCont) Collapse.getInstance(vm.$refs.userAttsCont).hide();
                        vm.isCollapsed = false;
                        vm.isEditing = false;
                        ScNotification.growlSuccessMsg('User Workshop Reference successfully updated');
                    }

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'saving user details');
                });
            }

        }
    };

    let _dataManager = null;

    export default {
        name: "UserWorkspaceAttributes",
        mixins: [ MainAppMixin ],
        components: {ScIcon},
        data () {
            return {
                isCollapsed: false,
                userWsRef: null,
                isEditing: false,
            };
        },
        props : {
            userId: [String, Number],
            wsRef: [String],
            canEditUser: {type: Boolean}
        },
        mounted () {
            $(this.$refs.userAttsCont).on('show.bs.collapse', () => { this.toggleIsCollapsed() });
            $(this.$refs.userAttsCont).on('hide.bs.collapse', () => { this.toggleIsCollapsed() });

            this.userWsRef = this.wsRef;
            _dataManager = new DataManager();
        },
        beforeUnmount () {
            this.isEditing = false;
        },
        methods: {
            updateAttributes () {
                _dataManager.saveUserReference(this);
            },
            toggleIsCollapsed () {
                this.isCollapsed = !this.isCollapsed;
            },
            toggleIsEditing () {
                this.isEditing = !this.isEditing;
            }
        },
        computed: {
            hasReference () {
                return (this.userWsRef && this.userWsRef.length > 0);
            }
        }
    }
</script>
