<template>
    <teleport to=".sc-modals-anchor">
        <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
             class="modal fade" tabindex="-1">

        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">
                        <span v-if="currentGroupName">{{ currentGroupName }}</span>
                        <span v-else>All Users</span>
                        Access</h4>
                </div>
                <div class="modal-body clearfix" >
                    <div v-show="isLoading" class="text-center my-4">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                    <div v-if="showcases.length > 0">
                        <p v-if="currentGroupId === 0">The following presentations have "Limit View Access" disabled.  They can be seen by
                            anyone in the workshop:</p>
                        <p v-else >This group is listed in "Limit View Access" for the following presentations:</p>

                        <table class="table">
                            <tr v-for="sc in showcases" :key="'sc' + sc.id" class="border-top">
                                <td class="text-center align-middle">
                                    <img v-if="sc.url_thumb" :src="sc.url_thumb" alt="Thumbnail" class="m-3 p-0"
                                         style="max-width: 50px; max-height: 50px;"/>
                                </td>
                                <td class="align-middle text-break">{{ sc.title }}</td>
                                <td class="align-middle text-end px-0">
                                    <a v-if="mainMxUwCanPerform('a:list_pres_for_edit')" href="#"
                                       @click.prevent="openManageAccessModal(sc.id)" class="btn btn-link border-0">
                                        <ScIcon name="users"/> Manage access</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else-if="!isLoading" class="text-muted text-center my-4">
                        <p v-if="currentGroupId === 0">There are no published presentations with "Limit View Access" disabled.</p>
                        <p v-else>There are no published presentations that have this group listed in "Limit View Access".</p>
                    </div>
                    <button v-if="currentGroupId !== 0" type="button" class="btn btn-link"
                        @click.prevent="viewPresWithOpenAccess">
                        <ScIcon name="lock" class="ms-3"/> Presentation view access for all users</button>
                </div>
            </div>
        </div>

            <ManageAccessModal></ManageAccessModal>
        </div>
    </teleport>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ManageAccessModal from '../presentation/manage-access/ManageAccessModal.vue';
    import MainAppMixin from '../MainAppMixin';

    export default {
        name: "PresentationAccessListModal",
        mixins: [MainAppMixin],
        components: {ManageAccessModal, ScIcon},
        emits: ['modal-was-hidden'],
        props: {
            workspaceId: {type: Number, default: null},
            groupId: {type: Number, default: null},
            groupName: {type: String, default: null},
        },
        data: function() {
            return {
                showcases: [],
                isLoading: false,
                currentGroupId: 0,
                currentGroupName: null,
            }
        },
        mounted() {
            this.currentGroupId = this.groupId;
            this.currentGroupName = this.groupName;
            this.loadData();
        },
        methods: {
            modalWasHidden() {
                this.$emit('modal-was-hidden');
            },
            openManageAccessModal (scId) {
                ManageAccessModal.modal({presentation_id: scId}).then((dataChanged) => {
                    // reload data if manage access has changed
                    if (dataChanged)
                        this.loadData();
                });
            },
            viewPresWithOpenAccess() {
                this.currentGroupId = 0;
                this.currentGroupName = null;
                this.loadData();
            },
            loadData () {
                this.isLoading = true;
                this.showcases.splice(0);
                $.ajax({
                    method: 'GET',
                    url: '/main/users/ajax_group_showcases_list',
                    data: {
                        workspace_id: this.workspaceId,
                        group_id: this.currentGroupId
                    }

                }).done((data) => {
                    if (data.status === 'ok')
                        this.showcases = data.showcases;

                }).always(() => {
                    this.isLoading = false;

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'loading access');
                });
            }
        },

    }
</script>
