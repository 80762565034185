<template>
    <div class="p-3 mb-4 border-top">
        <h3 class="mb-4">Groups</h3>
        <div class="row g-0">
            <div class="col-2 mb-3">
                <label class="mt-2">Add to group</label>
            </div>
            <div class="col-6 mb-3">
                <ScSelectWithSearchAndGroups
                    :items="[ filteredGroups ]"
                    :emptyAfterSelection="true"
                    placeholder="Select a group"
                    @sc-select-item="addToGroup" class="my-auto">
                </ScSelectWithSearchAndGroups>
            </div>
            <div class="col-4 text-end">
                <a class="btn btn-outline-secondary" href="#" @click.prevent="createGroup">
                    Create a new group <ScIcon name="plus" class="float-end ms-2"/>
                </a>
            </div>

            <div class="col-12 mt-3">
                <button v-for="i in partOfGroup" :key="'b' + i.id"  type="button" class="btn btn-outline-secondary me-2 mt-2">
                   <router-link :to="{ name: 'user-group-user-list', params: { workshopId: mainMxCurrentWorkshopId, groupId: i.group_id }}"
                        style="white-space: break-spaces;">
                            {{ i.name }}</router-link>
                    <span class="ms-2 p-0 m-0 text-muted" style="margin-top:2px !important;"
                          v-sc-tooltip="'Remove user from group'" @click.prevent="removeFromGroup(i)">&times;</span>
                </button>
            </div>
        </div>
        <UserNameGroupModal></UserNameGroupModal>
    </div>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // isEmpty, findWhere, where
    import UserNameGroupModal from './UserNameGroupModal.vue';
    import ScSelectWithSearchAndGroups from '../global/ScSelectWithSearchAndGroups.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';

    let DataManager = function () {
        return {
            userGroups (vm) {
                $.ajax({
                    type: 'GET',
                    url: '/main/users/ajax_user_groups_v2',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId
                    }

                }).done(function (data) {
                    //console.log(data);
                    vm.loadedUserGroups = data.all_user_groups;

                }).always(function () {

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'loading groups');
                });
            },
            loadGroups (vm) {
                $.ajax({
                    type: 'GET',
                    url: '/main/users/ajax_get_usergroups_v2',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        user_id: vm.userId
                    }

                }).done(function (data) {
                    for (let g of data) {
                        g.showName = g.name;
                        g.id = g.userGroupId;
                    }
                    vm.loadedGroups = data;

                }).fail(function (jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'loading group list');
                });

            },
            saveGroup (group, vm) {
                $.ajax({
                    type: "POST",
                    url: '/main/users/ajax_save_usergroups_v2',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        id: group.id,
                        name: group.name
                    },

                }).done(function(data) {
                    //console.log('saved Group', data, vm.loadedGroups);
                    if (data.status === 'error') {
                        ScNotification.growlErrMsg(data.details);
                        return;
                    }
                    _dataManager.loadGroups(vm);
                    ScNotification.growlSuccessMsg('Group "' + group.name + '" was created.');

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'updating user groups');
                });

            },
            saveUserToGroup (vm) {
                $.ajax({
                    url: '/main/users/ajax_handle_user_in_group_v2',
                    type: 'POST',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        user_id: vm.userId,
                        user_group_id: vm.selectedGroup.id,
                        selected: true
                    }

                }).done(function(data){
                    //console.log(data, vm.partOfGroup);
                    if (data.status !== 'ok')
                        ScNotification.growlErrMsg(data.details);

                    else
                        if (!vm.partOfGroup.some(item => item.group_id === vm.selectedGroup.id)) {
                            _dataManager.userGroups(vm);
                            ScNotification.growlSuccessMsg('User added to group ' + vm.selectedGroup.name );
                            vm.selectedGroup = null;
                        } else
                            ScNotification.growlErrMsg("User already in group!");

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'adding');
                });

            },
            deleteUserFromGroup (vm, group) {
                $.ajax({
                    type: 'POST',
                    url: '/main/users/ajax_handle_user_in_group_v2',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        selected: false,
                        user_group_id: group.group_id,
                        user_id: vm.userId
                    }
                }).done(function(data) {
                    //console.log(data);
                    ScNotification.growlSuccessMsg('User removed from group.');
                    if (data.status === 'ok')
                        _dataManager.userGroups(vm);

                }).fail(function(xhr) {
                    ScNotification.growlErrMsg('Error saving. ' + xhr.status);
                });

            },
        }
    };

    let _dataManager = null;

    export default {
        name: "UserGroupDetails",
        mixins: [ MainAppMixin ],
        components: { ScSelectWithSearchAndGroups, ScIcon, UserNameGroupModal },
        props : {
            user: Object,
        },
        data () {
            return {
                userGroupId: 0,
                loadedGroups: [],
                loadedUserGroups: [],
                selectedGroup: null,
            };
        },
        computed: {
            userId () {
                if (this.user) return this.user.id;
                return null;
            },

            userIsInGroup () {
                let groups = _.where(this.loadedUserGroups, { user_id: this.user.id });
                if (!groups)
                    return this.loadedGroups;

                for (let g of groups) {
                    let find = _.findWhere(this.loadedGroups, { userGroupId: g.group_id });
                    if (find) {
                        g.name = find.name;
                        g.showName = g.name;
                    }
                }
                return groups;
            },
            partOfGroup () {
                if (this.user && this.loadedUserGroups) {
                    if (!_.isEmpty(this.userIsInGroup)) {
                        return this.userIsInGroup;
                    }
                }
                return [];
            },
            filteredGroups () {
                if (this.user && this.loadedUserGroups && this.userIsInGroup.length > 0) {
                    let notPart = [];

                    for (let lg of this.loadedGroups) {
                        if (!_.findWhere(this.userIsInGroup, {group_id: lg.userGroupId}))
                            notPart.push(lg);
                    }

                    return notPart;
                }
                return this.loadedGroups;
            }
        },
        mounted () {
            _dataManager = new DataManager();
            if (this.userId) {
                _dataManager.loadGroups(this);
                _dataManager.userGroups(this);
            }
        },
        methods: {
            createGroup () {
                //console.log('create new group');
                let that = this;
                UserNameGroupModal.modal({ groupId: -1, groupName: '' }, (callback) => {
                    _dataManager.saveGroup(callback, that);
                });
            },
            addToGroup (group) {
              this.selectedGroup = group;
              _dataManager.saveUserToGroup(this);
            },
            removeFromGroup (group) {
                //console.log('remove from', group.name, group);
                _dataManager.deleteUserFromGroup(this, group);
            }
        },
        watch: {
            userId (nv) {
                if (nv) {
                    _dataManager.loadGroups(this);
                    _dataManager.userGroups(this);
                }
            }
        }
    }
</script>
