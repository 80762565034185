<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal" @vb-shown-bs-modal="setFocus"
         @vb-hidden-bs-modal="isModalShown=false"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <div class="modal-header text-center">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">
                        {{ modalTitle }} group
                    </h4>
                </div>
                <div class="modal-body">
                    <div id="form-new-group">
                        <input placeholder="Enter a group name" class="form-control" type="text" ref="groupInput"
                               name="group-name" v-model.trim="groupName" @keyup.enter.prevent="returnValue()"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary-orange fw-bold"
                            v-on:click.prevent="returnValue()">{{ modalButton }}</button>
                </div>

            </div>
        </div>
    </div>
    </teleport>
</template>

<script>

    import ScNotification from "../../shared/common/ScNotification.vue";

    let _ins = null;

    export default {
        name: 'UserNameGroupModal',
        data () {
            return {
                isModalShown: false,
                successCallback: null,
                groupName: 'New Group',
                groupId: -1,
            };
        },
        created () {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            setFocus () {
                this.$refs.groupInput.select();
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            returnValue () {
                if(!this.groupName) {
                    ScNotification.growlErrMsg('Please enter a name');
                } else {
                    this.successCallback({name: this.groupName, id: (this.groupId === -1) ? '' : this.groupId});
                    this.hideModal();
                }
            },
            toggleBsModal(options, successCallback) {
                this.groupName = options.groupName;
                this.groupId = options.groupId;
                this.successCallback = successCallback;
                this.isModalShown = true;
            }
        },
        computed: {
            modalTitle () {
                 return (this.groupId !== -1) ? "Rename" : "New";
            },
            modalButton () {
                return (this.groupId !== -1) ? "Rename" : "Create";
            }
        },

        //
        // public functions
        //

        modal (options, successCallback) {
            _ins.toggleBsModal(options, successCallback);
        }


    }

</script>
