<template>
    <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="$emit('modal-was-hidden')"
         @vb-hide-bs-modal="canModalHide"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div v-if="!codeGenerated" class="modal-content shadow-lg">
                <div class="modal-header">
                    <h4 class="modal-title w-100 text-center text-break">Click next to send user code via email</h4>
                </div>
                <div class="modal-body">
                    <div>
                        Tell the user to check their email for the forgot password code, and they can reset their
                        password using the "Forgotten your password" > "I have a code already" option in the app.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"
                            :disabled="isGeneratingCode">Cancel</button>
                    <button type="button" class="btn btn-primary-orange fw-bold" style="min-width:4rem"
                       @click.prevent="generatePasswordResetCode" :disabled="isGeneratingCode">
                        <span v-if="!isGeneratingCode">Next</span>
                        <ScIcon v-if="isGeneratingCode" name="spinnerFW" class="mx-3" />
                    </button>
                </div>
            </div>

            <div v-if="codeGenerated" class="modal-content shadow-lg">
                <div  class="modal-header">
                    <h4 class="modal-title mx-auto">Reset Password Code Emailed</h4>
                </div>
                <div class="modal-body">
                    <div>
                        Tell the user to check their email for the forgot password code, and they can reset their
                        password using the "Forgotten your password" > "I have a code already" option in the app.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary-orange fw-bold" @click.prevent="hideModal">Done</button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';

    export default {
        name: 'UserResetPasswordConfirmModal',
        emits: ['modal-was-hidden'],
        mixins: [ MainAppMixin ],
        components: {ScIcon},
        props: {
            userId: {type: Number, default: null},
        },
        data () {
            return {
                codeGenerated: false,
                isGeneratingCode: false
            }
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (this.isGeneratingCode) e.preventDefault();
            },
            generatePasswordResetCode () {
                this.isGeneratingCode = true;
                this.codeGenerated = false;
                $.ajax({
                    type: "POST", url: '/main/users/ajax_generate_reset_password_code',
                    data: {user_id: this.userId, workspace_id: this.mainMxCurrentWorkshopId}
                }).then((data) => {
                    if (data.status === 'ok') {
                        this.codeGenerated = true;
                    } else if (data.status === 'error') {
                        ScNotification.growlErrMsg('Unable to generate password code. Please try again.');
                    }
                }).always(() => {
                    this.isGeneratingCode = false;

                }).fail((jqXHR) => {
                    if (jqXHR.status === 0) {  // offline
                        ScNotification.growlErrMsg('You are not connected to the internet');
                    } else if (jqXHR.status === 500) {
                        ScNotification.growlErrMsg('Server error.  Please try again in one hour.');
                    } else {
                        ScNotification.growlErrMsg('Could not contact app server (' + jqXHR.status + ')');
                    }
                });
            }
        }

    }

</script>

