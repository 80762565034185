<template>
    <div class="p-3 mb-4 border-top">
        <h3 class="my-2">
            Role
        </h3>
        <ul class="list-unstyled m-3 user-role-list">

            <li @click.prevent="changeRole('r:viewer')" class="ms-3 mb-4">
                <div class="form-check">

                    <input type="radio" class="form-check-input" id="viewer-radio" name="radio"
                           :disabled="!canEditUser"
                           :checked="roleCode === 'r:viewer'" title="Change role to Viewer" />

                    <label class="form-check-label d-inline-flex" for="viewer-radio">
                        <ScIcon name="userSmallMedium" class="mx-4" title="Viewer"/>
                        <div class="d-block my-auto">
                            <strong>Viewer</strong> - Default user role. Only allowed to view their own activity and download presentations to their own devices.
                        </div>

                    </label>
                </div>
            </li>

            <li @click.prevent="changeRole('r:reporter')" class="ms-3 mb-4">
                  <div class="form-check">
                      <input type="radio" class="form-check-input" id="reporter-radio" name="radio"
                             :disabled="!canEditUser"
                             :checked="roleCode === 'r:reporter'" title="Change role to Reporter" />
                      <label class="form-check-label d-inline-flex" for="reporter-radio">
                          <ScIcon name="userBountyHunterSmallMedium" class="mx-4" title="Reporter"/>
                          <div class="d-block my-auto">
                              <strong>Reporter</strong> - View all presentations, view reports (analytics, sharing history, forms, activity).
                          </div>
                      </label>
                  </div>
              </li>

            <li @click.prevent="changeRole('r:editor')" class="ms-3 mb-4">
                <div class="form-check">

                    <input type="radio" class="form-check-input" id="editor-radio" name="radio"
                           :disabled="!canEditUser"
                           :checked="roleCode === 'r:editor'" title="Change role to Editor" />

                    <label class="form-check-label d-inline-flex" for="editor-radio">
                        <ScIcon name="userNinjaSmallMedium" class="mx-4" title="Editor"/>
                        <div class="d-block my-auto">
                            <strong>Editor</strong> - Manage files, create, edit and view all presentations, view analytics.
                        </div>

                    </label>
                </div>
            </li>

            <li @click.prevent="changeRole('r:manager')" class="ms-3 mb-4">
                <div class="form-check">

                    <input type="radio" class="form-check-input" id="manager-radio" name="radio"
                           :disabled="!canEditUser"
                           :checked="roleCode === 'r:manager'" title="Change role to Manager" />

                    <label class="form-check-label d-inline-flex" for="manager-radio">
                        <ScIcon name="userAstronautSmallMedium" class="mx-4" title="Manager"/>
                        <div class="d-block my-auto">
                            <strong>Manager</strong> - Add and manage users, view analytics, view all presentations.
                        </div>

                    </label>
                </div>
            </li>

            <li @click.prevent="changeRole('r:admin')" class="ms-3 mb-4">
                <div class="form-check">

                    <input type="radio" class="form-check-input" id="admin-radio" name="radio"
                           :disabled="!canEditUser"
                           :checked="roleCode === 'r:admin'" title="Change role to Admin" />

                    <label class="form-check-label d-inline-flex" for="admin-radio">
                        <ScIcon name="userCowboyMedium" class="mx-4" title="Admin"/>
                        <div class="d-block my-auto">
                            <strong>Admin</strong> - Create, edit, view all presentations, create and manage users, workshop settings and subscription info. At least one user must have the Admin role.
                        </div>

                    </label>
                </div>
            </li>
        </ul>

    </div>
</template>

<script>
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "UserRoleDetails",
        emits: ['change-role'],
        components: {ScIcon},
        props: {
            roleCode: String,
            canEditUser: Boolean
        },
        methods: {
            changeRole (role) {
                if (!this.canEditUser) return;
                this.$emit('change-role', role);
            }
        }
    }
</script>

<style scoped>

    .user-role-list li:hover {
        color: #008ab0;
        cursor: pointer;
    }

</style>